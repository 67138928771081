import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import { Box, Heading } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h2>{`Anatomy`}</h2>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361202-0f1735a7-0409-4c51-b095-1cdd0bc92bb3.png" />
    <h3>{`Table header`}</h3>
    <p>{`The table header shows contextual information about the table and render controls that affect the table.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Title:`}</strong>{` A concise label that describes the table and its contents`}</li>
      <li parentName="ul"><strong parentName="li">{`Subtitle:`}</strong>{` A brief description to adds more context for the table when the title alone is not enough`}</li>
      <li parentName="ul"><strong parentName="li">{`Actions:`}</strong>{` Controls that affect the entire table`}</li>
      <li parentName="ul"><strong parentName="li">{`Filter:`}</strong>{` An input used to filter table data to make it easier for the user to focus only on the rows relevant to their task. Even though the filter affects the entire table, it's not considered an "action".`}</li>
    </ul>
    <h3>{`Column headers`}</h3>
    <p>{`A very short label that describes the data in the column.`}</p>
    <p>{`Column headers are buttons that sort rows by the column data in ascending or descending order. Sort functionality may be disabled on a column-by-column basis.`}</p>
    <p>{`The column that contains row actions does not have a visible column header.`}</p>
    <h3>{`Row headers`}</h3>
    <p>{`A row header identifies the item being represented in the row. They are typically the first cell in the row, and are visually distinct from the other cells in the row.`}</p>
    <h3>{`Row actions`}</h3>
    <p>{`Actions that affect the item represented in the row.`}</p>
    <h3>{`Data cells`}</h3>
    <p>{`The data for each row that is described by its corresponding column header and optional row header.`}</p>
    <h3>{`Pagination footer`}</h3>
    <p>{`The pagination footer shows the user where they are in relation to the total number of rows and provides controls for navigating all rows.`}</p>
    <h2>{`Usage`}</h2>
    <h3>{`When to use a data table`}</h3>
    <ul>
      <li parentName="ul">{`To navigate a lot of information at once and to make it easy to compare data points between rows or columns`}</li>
      <li parentName="ul">{`If the data is easy to understand when all data points are displayed in a flat hierarchy`}</li>
    </ul>
    <h3>{`When to use something else`}</h3>
    <h4>{`Use a list or something else`}</h4>
    <ul>
      <li parentName="ul">{`When there are columns where the cells will usually be empty`}</li>
      <li parentName="ul">{`If the data is easier to understand with grouping and hierarchy (sections, headings, subheadings, etc.)`}</li>
      <li parentName="ul">{`When rows and columns are only a means of layout`}</li>
      <li parentName="ul">{`For data with longform content such as paragraphs or long lists`}</li>
    </ul>
    <h4>{`Use a data grid`}</h4>
    <ul>
      <li parentName="ul">{`If the primary purpose is to edit or otherwise interact with the cells (like a spreadsheet)`}</li>
      <li parentName="ul">{`If rows or columns support some kind of user interaction`}</li>
    </ul>
    <h3>{`Best practices`}</h3>
    <Box display="grid" gridTemplateColumns={['', '', '', '', '18rem 1fr']} gridGap={[0, 0, 0, 0, 3]} sx={{
      '> h4': {
        margin: 0
      }
    }} mdxType="Box">
      <h4>{`Order rows intuitively`}</h4>
      <div>
        <p>{`Data should be initially rendered in an intuitive order. For example, starting with the most recently created items.`}</p>
        <p>{`By default, sort alphabetically by the first column's content. Rows will be re-ordered as the user updates the sort
parameters`}</p>
      </div>
      <h4>{`Minimize the number of columns`}</h4>
      <p>{`It's easier to scan many rows than it is to scan many columns. Consider swapping columns and rows when it's unlikely that there will ever be more rows than columns.`}</p>
      <h4>{`Adapt column widths when necessary`}</h4>
      <p>{`Use the column width options to appropriately size the columns depending on the content and the available horizontal space.`}</p>
      <h4>{`Keep column headers short`}</h4>
      <p>{`Column headers are short labels used to define the kind of data that is shown in that column.`}</p>
      <h4>{`Keep cell content concise`}</h4>
      <p>{`Cells should represent data in the shortest possible format. This makes the table easier to scan, and preserves horizontal space.`}</p>
      <h4>{`Don't use a single column to show multiple pieces of data`}</h4>
      <p>{`The column header should accurately represent the data point rendered in the column's cells. It would also be unclear which data point is being used when sorting.`}</p>
      <h4>{`Make numbers easier to compare`}</h4>
      <p>{`Right-align numeric values and use the `}<inlineCode parentName="p">{`tabular-num`}</inlineCode>{` `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-numeric"
        }}>{`font variant`}</a>{` when possible.`}</p>
      <h4>{`Avoid wrapping or truncation of cell content as much as possible`}</h4>
      <div>
        <p>{`Ideally, the cell content is very short and the column will be wide enough to accommodate its content. In cases where it's not possible to fit the content, you may wrap or truncate the content.`}</p>
        <p>{`Cells that are likely to contain long strings may choose to wrap the content. This is the preferred way to accommodate long content because it doesn't hide the content.`}</p>
        <p>{`Content truncation is available as a last resort when column widths are set. The full content may be exposed in a tooltip.`}</p>
      </div>
      <h4>{`By default, leave empty cells blank`}</h4>
      <p>{`You may show a message to explain an absence of data. Just don't use a character like “x” or “-”. It creates a weird experience for assistive technologies like screen readers.`}</p>
      <h4>{`Use “skeleton” placeholders to indicate loading content`}</h4>
      <div>
        <p>{`The placeholder should match the real content as closely as possible.Use the following properties to adjust the size and alignment of the placeholder:`}</p>
        <ul>
          <li parentName="ul">{`column width`}</li>
          <li parentName="ul">{`alignment`}</li>
          <li parentName="ul">{`whether or not there is a leading visual`}</li>
        </ul>
        <p>{`Don't worry about whether the text wraps. Ideally, the height of the cell will not change once the data is loaded. However, it wouldn't be possible to know how many lines of text the content wraps to.`}</p>
      </div>
      <h4>{`Use pagination to accommodate tables with a large dataset`}</h4>
      <p>{`By paginating, the user can focus on segments of a large dataset without being overwhelmed. Pagination also helps with performance by reducing the amount of data to be downloaded and the amount of content that needs to be rendered.`}</p>
      <h4>{`Don't put too many or too few rows per page`}</h4>
      <div>
        <p>{`If page length is too short, it would be annoying to browse the data in small segments. If page length is too long, users could get lost or overwhelmed.`}</p>
        <p>{`The following factors influence what the ideal page length would be for your use-case:`}</p>
        <ul>
          <li parentName="ul">{`Whether rows can be filtered`}</li>
          <li parentName="ul">{`Number of columns`}</li>
          <li parentName="ul">{`Visual complexity of the rendered cells`}</li>
          <li parentName="ul">{`Amount of context needed when reviewing the data`}</li>
        </ul>
        <p>{`These factors make it difficult to recommend an “ideal” page size for all use cases, but 20 rows is a good place to start.`}</p>
      </div>
      <h4>{`Communicate when the table has no data to show`}</h4>
      <p>{`Show a `}<inlineCode parentName="p">{`Blankslate`}</inlineCode>{` component in place of the table`}</p>
      <h4>{`Consider small screens`}</h4>
      <div>
        <p>{`Some strategies for adapting the table for narrow viewports include:`}</p>
        <ul>
          <li parentName="ul">{`Use the column width options`}</li>
          <li parentName="ul">{`Remove less important columns`}</li>
          <li parentName="ul">{`Scroll horizontally`}</li>
        </ul>
      </div>
    </Box>
    <h3>{`Do's and Don'ts`}</h3>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361204-99082cdf-36d7-4248-9a77-0bf58ee7fae5.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Use a data table for data in a flat hierarchical structure
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361206-e066a62d-40dd-4956-aa69-e2766c3b7d0b.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Don't use a data table for information that has hierarchical relationships
    </Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361208-4191728f-b130-4c44-b834-da7dc29abc81.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Restrict the data in a column to represent one type of data
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361210-dcd62746-c10e-40dc-ba6d-7b6c509693e3.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Don't group data in a column cell that isn't described by the column header
    </Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361212-b35c07de-4f66-4dfb-b2ac-bffd01723879.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Leave the cell blank {`(preferred)`} or show text that explains the absence
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361213-5bf83f35-ec56-4aa4-807b-3687fbef6d0a.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Don't show a character, icon, or emoji to indicate a cell is empty
    </Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361215-c7f15b31-bf91-49ec-8ee0-f85af5c86588.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Allow the title in the page header to act as a table title when appropriate
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361216-a10a8d67-5f04-4bf9-9b82-9ca851731698.png" />
    <Caption role="presentation" color="fg.subtle" mdxType="Caption">
      Don't repeat or rephrase the title in the page header as the table title
    </Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Options`}</h2>
    <h3>{`Column widths`}</h3>
    <h4>{`Grow (default)`}</h4>
    <p>{`Stretch to fill available space, and min width is the width of the widest cell in the column. This is the same behavior as a CSS grid column with the value `}<inlineCode parentName="p">{`minmax(auto, 1fr)`}</inlineCode>{`.`}</p>
    <p>{`May have a min and max width constraint.`}</p>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3,
      'img': {
        width: '100%'
      }
    }} mdxType="Box">
  <div>
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/205382519-b97666be-05bd-4449-a4d9-b924c3bbe70a.png" />
  </div>
  <div>
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/205361220-078f56f6-0681-4e64-bab3-7860f179d066.png" />
  </div>
    </Box>
    <h4>{`Grow or collapse`}</h4>
    <p>{`Stretch to fill available space in the parent `}<strong parentName="p">{`or shrink`}</strong>{` to fit in the available space in the parent.`}</p>
    <p>{`May have a min and max width constraint. Without a min width constraint, the column could collapse to a width of 0px.`}</p>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3,
      'img': {
        width: '100%'
      }
    }} mdxType="Box">
  <div>
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/231308670-2979a7db-9207-4c7e-a30e-56c0afc15392.png" />
  </div>
  <div>
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/231308671-fba97361-45f7-4c4b-bf5d-54bf9940fe1b.png" />
  </div>
    </Box>
    <h4>{`Auto`}</h4>
    <p>{`The column is the width of its widest cell. Not intended for use with columns whose content length varies a lot because a layout shift will occur when the content changes.`}</p>
    <p>{`May have a min and max width constraint.`}</p>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3,
      marginBottom: 3,
      'img': {
        width: '100%'
      }
    }} mdxType="Box">
  <div>
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361223-f9becdb3-64ca-44b2-a350-2d33e2b20b1f.png" />
  </div>
  <div>
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361224-f39ba3d5-feb4-464a-9059-2784bb91eec9.png" />
  </div>
    </Box>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <div>
        <h4>{`Explicit width`}</h4>
        <p>{`Will be exactly that width and will not grow or shrink to fill the parent.`}</p>
      </div>
      <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205385940-7dca1d5d-b2ae-4faf-a656-5b2bbec7b85f.png" />
    </Box>
    <h4>{`Action column`}</h4>
    <p>{`The action column would not accept a width. Its min-width would be the width of the actions.`}</p>
    <h4>{`Filling available space`}</h4>
    <p>{`If all columns have width constraints set and the row doesn't fill the table container, the difference is added to the width of the last column.`}</p>
    <h3>{`Cells`}</h3>
    <Box display="flex" flexDirection="column" sx={{
      gap: 3,
      'h4': {
        margin: 0
      }
    }} mdxType="Box">
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`Density`}</h4>
          <p>{`Options:`}</p>
          <ul>
            <li parentName="ul"><strong parentName="li">{`Condensed:`}</strong>{` Optimize for showing more information in a smaller area.`}</li>
            <li parentName="ul"><strong parentName="li">{`Normal (default):`}</strong>{` Shows a lot of information in a small area, but provides enough whitespace that the cells won't risk visually running together.`}</li>
            <li parentName="ul"><strong parentName="li">{`Spacious:`}</strong>{` Optimizes readability for tables with visually busy cell content.`}</li>
          </ul>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361229-8f29b673-93d5-414a-8e77-aa7b74e3c4b1.png" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`Text alignment`}</h4>
          <ul>
            <li parentName="ul"><strong parentName="li">{`Left (default):`}</strong>{` Matches natural reading direction of text in right-to-left writing systems.`}</li>
            <li parentName="ul"><strong parentName="li">{`Right:`}</strong>{` Optimizes for comparing column data that is easier to compare when it's right-aligned. Most commonly used for numeric data.`}</li>
          </ul>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361230-91f0dcb1-7b50-4df4-937f-76eaa7f12b36.png" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`Content fitting`}</h4>
          <ul>
            <li parentName="ul">{`Wrapping (default)`}</li>
            <li parentName="ul">{`Single-line truncation`}</li>
            <li parentName="ul">{`Multi-line truncation`}</li>
          </ul>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361233-640aadba-27d7-41ac-920f-16422d684873.png" />
      </Box>
    </Box>
    <h3>{`Row actions cell options`}</h3>
    <Box display="flex" flexDirection="column" sx={{
      gap: 3,
      'h4': {
        margin: 0
      }
    }} mdxType="Box">
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`1 action`}</h4>
          <p>{`Row actions are placed in the last column, and they don't require a visible column header.`}</p>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361234-183d525d-d23d-4e6a-85d7-b0a816c5215f.png" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`Multiple actions`}</h4>
          <p>{`If you have multiple actions for a row, start by pulling them into a dropdown menu.`}</p>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361236-f200108e-6fa2-4c38-b0eb-fa2f695c5e15.png" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`1 primary action, other actions in an overflow`}</h4>
          <p>{`If one of the actions is heavily used, pull it out for easier access. Do not pull out more than 1 action.`}</p>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361238-f5219090-dd2f-4c6c-a9ab-6bab8b43d74c.png" />
      </Box>
    </Box>
    <h2>{`Interactions`}</h2>
    <h3>{`Sorting`}</h3>
    <Box display="flex" flexDirection="column" sx={{
      gap: 3
    }} mdxType="Box">
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box as="p" flex={1} mdxType="Box">
  If a table is sortable, it must start with one column sorted on page load. Interacting with a column that is already
  sorted will toggle the sort between ascending and descending. If a column is not sorted yet, the first click sorts in
  ascending order.
        </Box>
        <CustomVideoPlayer width="456" loop src="https://user-images.githubusercontent.com/2313998/217949039-8d6a3062-e7ec-40f6-b6d9-b80494a7e89b.mp4" mdxType="CustomVideoPlayer" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`States`}</h4>
          <ul>
            <li parentName="ul">{`Unsorted (default)`}</li>
            <li parentName="ul">{`Ascending sort active`}</li>
            <li parentName="ul">{`Descending sort active`}</li>
            <li parentName="ul">{`Sort in progress`}</li>
          </ul>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361240-85add374-1599-48bf-b6f0-c8e958fbd2dc.png" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h3>{`Filtering`}</h3>
          <p>{`A `}<a parentName="p" {...{
              "href": "https://ui.githubapp.com/storybook/?path=/docs/recipes-filter--readme"
            }}>{`filter input (internal)`}</a>{` is used to write a query and only show rows that
match that query.`}</p>
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/207115488-96f5b4c6-b870-4fca-b049-9d7574eb48ca.png" />
      </Box>
    </Box>
    <h2>{`Accessibility`}</h2>
    <h3>{`Labeling and describing the table`}</h3>
    <p>{`The table must have a title, and can optionally have a subtitle to describe more context. If you encounter a case where there is enough context that the table doesn’t need a visible label, you may hide it so it’s only accessible to assistive technologies.`}</p>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
      <Box as="p" flex={1} mdxType="Box">
  If you use the table header, the table's title and subtitle will automatically be associated with the table for
  assistive technologies.
      </Box>
      <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361243-62d0b497-a4ba-44b5-8e3e-258cdd6e53e1.png" />
    </Box>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <Box as="p" flex={1} mdxType="Box">
  If your table's title exists somewhere else on the page (for example, in the PageHeader), then you must manually
  associate that contextual information with the table using ARIA.
      </Box>
      <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361244-8a1f527a-e1f3-45a3-ab13-5fdf82476038.png" />
    </Box>
    <h3>{`Labeling actions`}</h3>
    <Box display="flex" flexDirection="column" sx={{
      gap: 3
    }} mdxType="Box">
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box as="p" flex={1} mdxType="Box">
  The row header string will be prepended to the ARIA text of action buttons. For examples, Download {`{row header}`}“,
  “Actions:{`{row header}`}”
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361247-dfea530d-9989-442b-8a50-9c66aa5d3733.png" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box as="p" flex={1} mdxType="Box">
  To handle cases where the row header can be very long, we should give consumers the option to specify a shorter string
  to identify the rows. For example, if an issue title is used as the row header, we could use the issue number instead
  of the full issue title.
        </Box>
        <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/205361249-763772e1-d856-479e-a632-d185c27095db.png" />
      </Box>
      <h3>{`Pagination buttons`}</h3>
      <p>{`The pagination control for a DataTable uses `}<inlineCode parentName="p">{`button`}</inlineCode>{` elements, and not `}<inlineCode parentName="p">{`a`}</inlineCode>{` elements. This is because activating the pagination control requests data, and does not have a corresponding URL to link to.`}</p>
      <h3>{`Loading and sorting content`}</h3>
      <Box as="p" flex={1} mdxType="Box">
  It is important to ensure parity between what can be seen visually when a table is loading or sorting content and what is announced to assistive technology. This is provided by the DataTable component automatically, but must be considered if a custom loading solution is otherwise utilized. 
  For loading content via a custom loading method, this also includes the initial loading period. An announcement should not be made if content is retrieved before 750ms.
      </Box>
    </Box>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="DataTable" mdxType="AccessibilityLink" />
    <h2>{`Related components and patterns`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/action-bar"
        }}>{`ActionBar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/pagination"
        }}>{`Pagination`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      